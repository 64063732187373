import React from "react"

const NewsPage = () => {
  return (
    <>
      <h2>Strona w budowie</h2>
    </>
  )
}

export default NewsPage
